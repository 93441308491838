import React from "react";

function NoMatch() {
  return (
    <div>
      <h1>Page Not found</h1>
    </div>
  );
}

export default NoMatch;
